<template>
  <div>
    <div style="display: flex" v-if="userId">
      <div class="box">
        <!--      个人信息-->
        <div class="box1">
          <div style="display: flex; position: relative" v-if="editInfo === 0">
            <div class="editStyle" @click="edit()">
              <div class="editLogo"><img loading="lazy" src="../assets/edit.png" /></div>
              <div class="editTitle">编辑</div>
            </div>
            <div class="Photo">
              <!--            <el-upload-->
              <!--                class="avatar-uploader boxCardUploadImg"-->
              <!--                :action="baseURL + '/common/upload'"-->
              <!--                :show-file-list="false"-->
              <!--                :on-success="handleAvatarSuccess"-->
              <!--                :before-upload="beforeAvatarUpload"-->
              <!--                :on-error="handleAvatarError"-->
              <!--                accept=".png,.jpg,.jpeg"-->
              <!--            >-->
              <img
                :src="form.avatar ? baseURL + form.avatar : avator"
                class="avatar"
              />
              <!--            </el-upload>-->
            </div>
            <div style="margin-left: 16px">
              <div v-if="form.userName">
                <div class="userName" v-if="form.nickName">
                  {{ form.nickName }}({{ form.userName }})
                </div>
                <div class="userName" v-else>油气人才({{ form.userName }})</div>
              </div>
              <div v-else>
                <div class="userName" v-if="form.nickName">
                  {{ form.nickName }}
                </div>
                <div class="userName" v-else>油气人才</div>
              </div>
              <div style="display: flex; margin-top: 15px">
                <div class="iconStyle"><img loading="lazy" src="../assets/sex.png" /></div>
                <div v-if="form.sex !== null">
                  <div class="userSex" v-if="form.sex == 0">男</div>
                  <div class="userSex" v-if="form.sex == 1">女</div>
                </div>
                <div class="userSex" v-else>---</div>
                <div class="iconStyle"><img loading="lazy" src="../assets/workHour.png" /></div>
                <div class="userSex" v-if="form.workHour !== null">
                  {{ form.workHourCom }}
                </div>
                <div v-else class="userSex">---</div>
                <div class="iconStyle"><img loading="lazy" src="../assets/yesr.png" /></div>
                <div class="userSex" v-if="form.birthday !== null">
                  {{ form.old }}岁
                </div>
                <div v-else class="userSex">---</div>
                <div class="iconStyle"><img loading="lazy" src="../assets/work.png" /></div>
                <div class="userSex" v-if="form.jobStatus !== null">
                  {{ form.jobStatus }}
                </div>
                <div v-else class="userSex">---</div>
              </div>

              <div style="display: flex; margin-top: 15px">
                <div class="iconStyle">
                  <i class="el-icon-location-outline"></i>
                </div>
                <div class="userSex" v-if="form.currentCity !== null">
                  {{ form.currentCity }}
                </div>
                <div v-else class="userSex">---</div>
                <div class="iconStyle">
                  <i class="el-icon-mobile-phone"></i>
                </div>
                <div class="userSex" v-if="form.phonenumber !== null">
                  {{ form.phonenumber }}
                </div>
                <div v-else class="userSex">---</div>
                <div class="iconStyle"><i class="el-icon-message"></i></div>
                <div class="userSex" v-if="form.email !== null">
                  {{ form.email }}
                </div>
                <div v-else class="userSex">---</div>
              </div>
            </div>
          </div>
          <div v-if="editInfo === 1">
            <div style="background-color: #f8f8f8; padding: 20px 26px">
              <div style="display: flex; margin-left: 9px">
                <div class="Photo">
                  <el-upload
                    class="avatar-uploader boxCardUploadImg"
                    :action="baseURL + '/common/upload'"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :on-error="handleAvatarError"
                    accept=".png,.jpg,.jpeg"
                  >
                    <img
                      :src="form.avatar ? baseURL + form.avatar : avator"
                      class="avatar"
                    />
                    <!--                  <div class="wrap">-->
                    <!--                    <div>上传头像</div>-->
                    <!--                  </div>-->
                  </el-upload>
                </div>
                <div style="margin-left: 16px; height: fit-content">
                  <div class="userName1">上传头像</div>
                  <div class="userName2">上传真实头像更容易博得好感嗷~</div>
                </div>
              </div>
              <div class="information">
                <div>
                  <div class="title">姓名</div>
                  <div class="titleInput">
                    <el-input
                      v-model="form.userName"
                      max-length="20"
                      placeholder="请输入姓名，最多可输入20字"
                    ></el-input>
                  </div>
                </div>
                <div>
                  <div class="title">昵称</div>
                  <div class="titleInput">
                    <el-input
                      v-model="form.nickName"
                      max-length="20"
                      placeholder="请输入昵称，最多可输入20字"
                    ></el-input>
                  </div>
                </div>
                <div>
                  <div class="title">出生年月</div>
                  <div class="titleInput">
                    <el-date-picker
                      v-model="form.birthday"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                </div>
                <div>
                  <div class="title">参加工作时间</div>
                  <div class="titleInput">
                    <el-date-picker
                        v-model="form.workHour"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                </div>
                <div>
                  <div class="title">性别</div>
                  <div class="titleInput">
                    <div v-if="form.sex == 0" style="display: flex">
                      <div class="sexStyle1" @click="sexChange(0)">男</div>
                      <div
                        style="margin-left: 10%"
                        class="sexStyle2"
                        @click="sexChange(1)"
                      >
                        女
                      </div>
                    </div>
                    <div v-if="form.sex == 1" style="display: flex">
                      <div class="sexStyle2" @click="sexChange(0)">男</div>
                      <div
                        style="margin-left: 10%"
                        class="sexStyle1"
                        @click="sexChange(1)"
                      >
                        女
                      </div>
                    </div>
                    <div v-if="form.sex === null" style="display: flex">
                      <div class="sexStyle2" @click="sexChange(0)">男</div>
                      <div
                        style="margin-left: 10%"
                        class="sexStyle2"
                        @click="sexChange(1)"
                      >
                        女
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="title">求职状态</div>
                  <div class="titleInput">
                    <el-select
                      v-model="form.jobStatus"
                      placeholder="请选择求职状态"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.statusId"
                        :label="item.status"
                        :value="item.status"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div>
                  <div class="title">城市</div>
                  <div class="titleInput">
                    <el-cascader
                        :options="provinces"
                        v-model="form.currentCity"
                        style="width: 100%"
                        placeholder="城市"
                        :show-all-levels="false"></el-cascader>
                  </div>
                </div>
                <div>
                  <div class="title">电话</div>
                  <div class="titleInput">
                    <el-input
                      v-model="form.phonenumber"
                      disabled
                      max-length="20"
                      placeholder="请输入电话号码"
                    ></el-input>
                  </div>
                </div>
                <div>
                  <div class="title">邮箱</div>
                  <div class="titleInput">
                    <el-input
                      v-model="form.email"
                      disabled
                      max-length="20"
                      placeholder="请输入邮箱"
                    ></el-input>
                  </div>
                </div>

              </div>
              <div class="information" style="display: block">
                <div>
                  <div class="title">竞争优势</div>
                  <div class="titleInput">
                    <el-input
                        type="textarea"
                        placeholder="请简要描述你的职业优势"
                        v-model="form.advantages"
                    ></el-input>
                  </div>
                </div>
              </div>

              <div style="display: flex; justify-content: flex-end">
                <el-button class="cancleStyle" @click="cancleSubmit()"
                  >取消</el-button
                >
                <div class="confirmStyle" @click="confirmSubmit()">完成</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box2" v-if="editInfo === 0" style=" margin-top: 0; box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.05);padding: 0px 14px 32px 14px;">
            <div class="jobName">优势亮点：</div>
            <div>
              <div class="jobClass" style="margin:16px 15px 0 15px; width: 100%;font-size: 14px;
                  color:gray" v-if="form.advantages !== null">
                {{form.advantages}}
              </div>
              <div
                  style="margin:16px 15px 0 15px; width: 100%;"
                  v-else
              >
                <div
                    style="
                  width: 250px;
                  height: auto;
                  font-size: 14px;
                  color:gray
                "
                >
                  编辑优势亮点，展示个人态度!
                </div>
              </div>

            </div>


        </div>
        <!--      求职期望-->
        <div class="box2">
          <div>
            <div class="addStyle" @click="addJob()">
              <div class="addLogo"><img loading="lazy" src="../assets/add.png" /></div>
              <div class="addTitle">添加</div>
            </div>
            <div class="jobName">求职期望</div>
            <div class="jobClass" v-if="form.expectations.length !== 0">
              <li v-for="(jobs, index) in form.expectations" :key="index">
                <div class="jobStyle" v-if="jobs.jobedit == 0">
                  <div class="workName">{{ jobs.position }}</div>
                  <div class="span"></div>
                  <div class="workName">{{ jobs.salary }}</div>
                  <div class="span"></div>
                  <div class="workName">{{ jobs.industry }}</div>
                  <div class="span"></div>
                  <div class="workName">{{ jobs.city }}</div>

                  <div class="actions">
                    <div class="addStyle1" @click="editJob(jobs)">
                      <div class="addLogo">
                        <img loading="lazy" src="../assets/edit.png" />
                      </div>
                      <div class="addTitle">编辑</div>
                    </div>
                    <div class="addStyle2" @click="deleteJob(jobs)">
                      <div class="addLogo">
                        <img loading="lazy" src="../assets/delete.png" />
                      </div>
                      <div class="addTitle">删除</div>
                    </div>
                  </div>
                </div>

                <div class="jobStyle1" v-else>
                  <div style="background-color: #f8f8f8; padding: 20px 16px">
                    <div class="information">
                      <div>
                        <div class="title">期望职位</div>
                        <div class="titleInput">

                          <el-select
                            v-model="jobs.position"
                            filterable
                            placeholder="请选择期望职位"
                          >
                            <el-option
                              v-for="item in optionPosition"
                              :key="item.positionId"
                              :label="item.positionName"
                              :value="item.positionName"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div>
                        <div class="title">薪资要求</div>
                        <div class="titleInput">
                          <el-select
                            v-model="jobs.salary"
                            placeholder="请选择薪资要求"
                          >
                            <el-option
                              v-for="item in optionsSalary"
                              :key="item.id"
                              :label="item.content"
                              :value="item.content"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div>
                        <div class="title">期望行业</div>
                        <div class="titleInput">
                          <el-select
                              filterable
                            v-model="jobs.industry"
                            placeholder="请选择期望行业"
                          >
                            <el-option
                              v-for="item in optionsIndustry"
                              :key="item.industryId"
                              :label="item.industryContent"
                              :value="item.industryContent"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div>
                        <div class="title">城市</div>
                        <div class="titleInput">
                          <el-cascader
                              :options="provinces"
                              v-model="jobs.city"
                              style="width: 100%"
                              placeholder="城市"
                              :show-all-levels="false"></el-cascader>
                        </div>
                      </div>
                      <div>

                      </div>
                      <div style="display: flex; justify-content: flex-end">
                        <el-button
                          class="cancleStyle"
                          @click="cancleSubmitJob(jobs)"
                          >取消</el-button
                        >
                        <div
                          class="confirmStyle"
                          @click="confirmSubmitJob(jobs)"
                        >
                          完成
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            <div
              style="margin-top: 21px; width: 100%; text-align: center"
              v-else
            >
              <div
                style="
                  width: 250px;
                  height: auto;
                  font-size: 16px;
                  margin: 0 auto;
                "
              >
                <img loading="lazy" src="../assets/none.png" alt="" />
                暂无数据，快去添加吧
              </div>
            </div>
          </div>
        </div>
        <!--      工作经历-->
        <div class="box2">
          <div>
            <div class="addStyle" @click="addwork()">
              <div class="addLogo"><img loading="lazy" src="../assets/add.png" /></div>
              <div class="addTitle">添加</div>
            </div>
            <div class="jobName">工作经历</div>
            <div class="workClass" v-if="form.workExperience.length !== 0">
              <li v-for="(works, index) in form.workExperience" :key="index">
                <div class="workStyle" v-if="works.jobedit == 0">
                  <div style="display: flex">
                    <div v-if="works.workExperienceOverseas=='0'">
                      <div class="workCompanyName">
                        {{ works.workCompanyName }}(海外工作经历)
                      </div>
                    </div>
                    <div v-else>
                      <div class="workCompanyName">
                        {{ works.workCompanyName }}
                      </div>
                    </div>
                    <div class="workTime">
                      {{ works.workStartTime }}至{{ works.workEndTime }}
                    </div>

                  </div>
                  <div style="margin-top: 16px; display: flex">
                    <div class="indus">{{ works.workPositionName }}</div>
                  </div>
                  <div style="margin-top: 16px; display: flex">
                    <div class="industry">所在行业：</div>
                    <div class="indus">{{ works.workIndustry }}</div>
                  </div>
                  <div style="margin-top: 16px; display: flex">
                    <div class="industry2">工作内容：</div>
                    <div class="indusContent">
                      {{ works.workExperienceContent }}
                    </div>
                  </div>

                  <div class="workActions">
                    <div class="workStyle1" @click="editJob(works)">
                      <div class="addLogo">
                        <img loading="lazy" src="../assets/edit.png" />
                      </div>
                      <div class="addTitle">编辑</div>
                    </div>
                    <div class="workStyle2" @click="deleteWork(works)">
                      <div class="addLogo">
                        <img loading="lazy" src="../assets/delete.png" />
                      </div>
                      <div class="addTitle">删除</div>
                    </div>
                  </div>
                </div>
                <div class="workStyle" v-else>
                  <div
                    style="background-color: #f8f8f8; padding: 10px 16px 20px 0"
                  >
                    <div class="information">
                      <div>
                        <div class="title">公司名称</div>
                        <div class="titleInput">
                          <el-input
                            v-model="works.workCompanyName"
                            max-length="20"
                            placeholder="请输入任职公司"
                          ></el-input>
                        </div>
                      </div>
                      <div>
                        <div class="title">所属行业</div>
                        <div class="titleInput">
                          <el-select
                              filterable
                            v-model="works.workIndustry"
                            placeholder="请选择所在行业"
                          >
                            <el-option
                              v-for="item in optionsIndustry"
                              :key="item.industryId"
                              :label="item.industryContent"
                              :value="item.industryContent"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div>
                        <div class="title">在职时间</div>
                        <div class="titleInput" style="display: flex">
                          <div>
                            <el-date-picker
                              v-model="works.workStartTime"
                              type="date"
                              placeholder="选择入职日期"
                              value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                          </div>
                          <div style="padding: 8px">至</div>
                          <div>
                            <el-date-picker
                              v-model="works.workEndTime"
                              type="date"
                              placeholder="选择离职日期"
                              value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="title">职位名称</div>
                        <div class="titleInput" style="display: flex">
                          <el-input
                              v-model="works.workPositionName"
                              max-length="20"
                              placeholder="请填写您的职位"
                          ></el-input>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="title" style="margin-top:32px">海外工作经历
                      </div>
                      <div class="titleInput">
                        <div v-if="works.workExperienceOverseas === '0'" style="display: flex">
                          <div class="sexStyle1" @click="overChange(works)">是</div>
                          <div
                              style="margin-left: 3%"
                              class="sexStyle2"
                              @click="overChange1(works)"
                          >
                            否
                          </div>
                        </div>
                        <div v-if="works.workExperienceOverseas === '1'" style="display: flex">
                          <div class="sexStyle2" @click="overChange(works)">是</div>
                          <div
                              style="margin-left:3%"
                              class="sexStyle1"
                              @click="overChange1(works)"
                          >
                            否
                          </div>
                        </div>
                        <div v-if="works.workExperienceOverseas === ''" style="display: flex">
                          <div class="sexStyle2" @click="overChange(works)">是</div>
                          <div
                              style="margin-left: 3%"
                              class="sexStyle2"
                              @click="overChange1(works)"
                          >
                            否
                          </div>
                        </div>
                      </div>

                    </div>
                    <div>
                      <div class="title" style="margin-top: 32px">职业业绩</div>
                      <div class="titleInput">
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 5, maxRows: 5 }"
                          placeholder="请填写您的职责业绩，90%的招聘方会通过您的职责业绩了解您的工作能力，进而决定面试几率"
                          v-model="works.workExperienceContent"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div style="display: flex; justify-content: flex-end">
                      <el-button
                        class="cancleStyle"
                        @click="cancleSubmitWork(works)"
                        >取消</el-button
                      >
                      <div
                        class="confirmStyle"
                        @click="confirmSubmitWork(works)"
                      >
                        完成
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>

            <div
              style="margin-top: 21px; width: 100%; text-align: center"
              v-else
            >
              <div
                style="
                  width: 250px;
                  height: auto;
                  font-size: 16px;
                  margin: 0 auto;
                "
              >
                <img loading="lazy" src="../assets/none.png" alt="" />
                暂无数据，快去添加吧
              </div>
            </div>
          </div>
        </div>
        <!--      项目经历-->
        <div class="box2">
          <div>
            <div class="addStyle" @click="addPro()">
              <div class="addLogo"><img loading="lazy" src="../assets/add.png" /></div>
              <div class="addTitle">添加</div>
            </div>
            <div class="jobName">项目经历</div>
            <div class="workClass" v-if="form.projectExperience.length !== 0">
              <li v-for="(pros, index) in form.projectExperience" :key="index">
                <div class="workStyle" v-if="pros.jobedit == 0">
                  <div style="display: flex">
                    <div class="workCompanyName">{{ pros.projectName }}（{{pros.companyName}}）</div>
                    <div class="workTime">
                      {{ pros.projectStartTime }}至{{ pros.projectEndTime }}
                    </div>
                  </div>
                  <div style="margin-top: 16px; display: flex">
                    <div class="industry">承担角色：</div>
                    <div class="indus">{{ pros.projectRole }}</div>
                  </div>
                  <div style="margin-top: 16px; display: flex">
                    <div class="industry">项目描述：</div>
                    <div class="indus">{{ pros.projectContent }}</div>
                  </div>
                  <div style="margin-top: 16px; display: flex">
                    <div class="industry2">项目业绩：</div>
                    <div class="indusContent">
                      {{ pros.projectPerformance }}
                    </div>
                  </div>

                  <div class="workActions">
                    <div class="workStyle1" @click="editPro(pros)">
                      <div class="addLogo">
                        <img loading="lazy" src="../assets/edit.png" />
                      </div>
                      <div class="addTitle">编辑</div>
                    </div>
                    <div class="workStyle2" @click="deletePro(pros)">
                      <div class="addLogo">
                        <img loading="lazy" src="../assets/delete.png" />
                      </div>
                      <div class="addTitle">删除</div>
                    </div>
                  </div>
                </div>
                <div class="workStyle" v-else>
                  <div
                      style="background-color: #f8f8f8; padding: 10px 16px 20px 0"
                  >
                    <div class="information">
                      <div>
                        <div class="title">公司名称</div>
                        <div class="titleInput">
                          <el-input
                              v-model="pros.companyName"
                              max-length="20"
                              placeholder="请输入公司名称"
                          ></el-input>
                        </div>
                      </div>
                      <div>
                        <div class="title">项目名称</div>
                        <div class="titleInput">
                          <el-input
                              v-model="pros.projectName"
                              max-length="20"
                              placeholder="请输入项目名称"
                          ></el-input>
                        </div>
                      </div>
                      <div>
                        <div class="title">承担角色</div>
                        <div class="titleInput">
                          <el-input
                              v-model="pros.projectRole"
                              max-length="20"
                              placeholder="请输入在项目中承担的角色"
                          ></el-input>
                        </div>
                      </div>

                      <div>
                        <div class="title">项目起止时间</div>
                        <div class="titleInput" style="display: flex">
                          <div>
                            <el-date-picker
                                v-model="pros.projectStartTime"
                                type="month"
                                placeholder="选择项目开始日期"
                                value-format="yyyy-MM"
                            >
                            </el-date-picker>
                          </div>
                          <div style="padding: 8px">至</div>
                          <div>
                            <el-date-picker
                                v-model="pros.projectEndTime"
                                type="month"
                                placeholder="选择项目结束日期"
                                value-format="yyyy-MM"
                            >
                            </el-date-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="title" style="margin-top: 32px">项目描述</div>
                      <div class="titleInput">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 7, maxRows: 7 }"
                            placeholder="描述该项目，展示你的项目经验立刻打动招聘方！例如： 1.项目背景；2.项目目标； 3.项目概述
                              "
                            v-model="pros.projectContent"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div>
                      <div class="title" style="margin-top: 32px">项目业绩</div>
                      <div class="titleInput">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 5, maxRows: 5 }"
                            placeholder="请总结项目成果"
                            v-model="pros.projectPerformance"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div style="display: flex; justify-content: flex-end">
                      <el-button
                          class="cancleStyle"
                          @click="cancleSubmitPro(pros)"
                      >取消</el-button
                      >
                      <div class="confirmStyle" @click="confirmSubmitPro(pros)">
                        完成
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            <div
                style="margin-top: 21px; width: 100%; text-align: center"
                v-else
            >
              <div
                  style="
                  width: 250px;
                  height: auto;
                  font-size: 16px;
                  margin: 0 auto;
                "
              >
                <img loading="lazy" src="../assets/none.png" alt="" />
                暂无数据，快去添加吧
              </div>
            </div>
          </div>
        </div>
        <!--      教育经历-->
        <div class="box2">
          <div>
            <div class="addStyle" @click="addedu()">
              <div class="addLogo"><img loading="lazy" src="../assets/add.png" /></div>
              <div class="addTitle">添加</div>
            </div>
            <div class="jobName">教育经历</div>
            <div class="workClass" v-if="form.eduExperience.length !== 0">
              <li v-for="(edus, index) in form.eduExperience" :key="index">
                <div class="workStyle" v-if="edus.jobedit == 0">
                  <div style="display: flex;width: 100%">
                    <div class="photo">
                      <img src="@/assets/education.png">
                    </div>
                    <div style="width: 100%">
                      <div style="display: flex">
                        <div class="workCompanyName">
                          {{ edus.eduExperienceLocation }}
                        </div>
                        <div v-if="edus.eduRally==='0'" style="background-color: #bd0303;color:white;font-size: 8px;border-top-left-radius: 9px;border-bottom-right-radius: 9px;height: fit-content;padding: 2px 5px;margin: -5px 0 auto 5px">
                          统招
                        </div>
                        <div class="workTime">
                          {{ edus.eduStartTime }}至{{ edus.eduEndTime }}
                        </div>
                      </div>
                      <div style="margin-top: 16px; display: flex">
                        <div class="workName">{{ edus.eduSpeciality }}</div>
                        <div class="span"></div>
                        <div class="workName">{{ edus.eduEducation }}</div>
                      </div>
                      <div style="margin-top: 16px; display: flex">
                        <div class="industry">论文/毕设描述：</div>
                        <div class="indusLunwen">{{ edus.eduPaper }}</div>
                      </div>
                      <div style="margin-top: 16px; display: flex">
                        <div class="industry2">所获荣誉：</div>
                        <div class="indusContent">{{ edus.eduHonor }}</div>
                      </div>
                    </div>

                  </div>



                  <div class="workActions">
                    <div class="workStyle1" @click="editEdu(edus)">
                      <div class="addLogo">
                        <img loading="lazy" src="../assets/edit.png" />
                      </div>
                      <div class="addTitle">编辑</div>
                    </div>
                    <div class="workStyle2" @click="deleteEdu(edus)">
                      <div class="addLogo">
                        <img loading="lazy" src="../assets/delete.png" />
                      </div>
                      <div class="addTitle">删除</div>
                    </div>
                  </div>
                </div>
                <div class="workStyle" v-else>
                  <div
                    style="background-color: #f8f8f8; padding: 10px 16px 20px 0"
                  >
                    <div class="information">
                      <div>
                        <div class="title">学校名称</div>
                        <div class="titleInput">
                          <el-input
                            v-model="edus.eduExperienceLocation"
                            max-length="20"
                            placeholder="请输入学校名称"
                          ></el-input>
                        </div>
                      </div>
                      <div>
                        <div class="title">学历</div>
                        <div class="titleInput">
                          <el-select
                            v-model="edus.eduEducation"
                            placeholder="请选择所在学历"
                          >
                            <el-option
                              v-for="item in optionsEdu"
                              :key="item.id"
                              :label="item.content"
                              :value="item.content"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div>
                        <div class="title">专业</div>
                        <div class="titleInput">
                          <el-input
                            v-model="edus.eduSpeciality"
                            max-length="20"
                            placeholder="请输入所学专业"
                          ></el-input>
                        </div>
                      </div>
                      <div>
                        <div class="title">统招入学
                        </div>
                        <div class="titleInput">
                          <div v-if="edus.eduRally === '0'" style="display: flex">
                            <div class="sexStyle1" @click="eduChange(edus)">是</div>
                            <div
                                style="margin-left: 3%"
                                class="sexStyle2"
                                @click="eduChange1(edus)"
                            >
                              否
                            </div>
                          </div>
                          <div v-if="edus.eduRally === '1'" style="display: flex">
                            <div class="sexStyle2" @click="eduChange(edus)">是</div>
                            <div
                                style="margin-left:3%"
                                class="sexStyle1"
                                @click="eduChange1(edus)"
                            >
                              否
                            </div>
                          </div>
                          <div v-if="edus.eduRally === ''" style="display: flex">
                            <div class="sexStyle2" @click="eduChange(edus)">是</div>
                            <div
                                style="margin-left: 3%"
                                class="sexStyle2"
                                @click="eduChange1(edus)"
                            >
                              否
                            </div>
                          </div>
                        </div>

                      </div>
                      <div>
                        <div class="title">学习时间</div>
                        <div class="titleInput" style="display: flex">
                          <div>
                            <el-date-picker
                              v-model="edus.eduStartTime"
                              type="month"
                              placeholder="选择入学日期"
                              value-format="yyyy-MM"
                            >
                            </el-date-picker>
                          </div>
                          <div style="padding: 8px">至</div>
                          <div>
                            <el-date-picker
                              v-model="edus.eduEndTime"
                              type="month"
                              placeholder="选择毕业日期"
                              value-format="yyyy-MM"
                            >
                            </el-date-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="title" style="margin-top: 32px">
                        毕业论文/毕业设计描述
                      </div>
                      <div class="titleInput">
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 5, maxRows: 5 }"
                          placeholder="请输入毕业论文/毕业设计描述"
                          v-model="edus.eduPaper"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div>
                      <div class="title" style="margin-top: 32px">所获荣誉</div>
                      <div class="titleInput">
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 5, maxRows: 5 }"
                          placeholder="1. 在校担任职务...
2. 所获荣誉...
3. 主修课程...
4. 组织经历或社团经历.."
                          v-model="edus.eduHonor"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div style="display: flex; justify-content: flex-end">
                      <el-button
                        class="cancleStyle"
                        @click="cancleSubmitEdu(edus)"
                        >取消</el-button
                      >
                      <div class="confirmStyle" @click="confirmSubmitEdu(edus)">
                        完成
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            <div
              style="margin-top: 21px; width: 100%; text-align: center"
              v-else
            >
              <div
                style="
                  width: 250px;
                  height: auto;
                  font-size: 16px;
                  margin: 0 auto;
                "
              >
                <img loading="lazy" src="../assets/none.png" alt="" />
                暂无数据，快去添加吧
              </div>
            </div>
          </div>
        </div>

        <!--      <div class="contain">-->
        <!--        <el-form-->
        <!--            :label-position="labelPosition"-->
        <!--            label-width="100px"-->
        <!--            :model="form"-->
        <!--        >-->
        <!--          <el-form-item label="头像" class="avatorBox" required>-->
        <!--            <el-upload-->
        <!--                class="avatar-uploader boxCardUploadImg"-->
        <!--                :action="baseURL + '/common/upload'"-->
        <!--                :show-file-list="false"-->
        <!--                :on-success="handleAvatarSuccess"-->
        <!--                :before-upload="beforeAvatarUpload"-->
        <!--                :on-error="handleAvatarError"-->
        <!--                accept=".png,.jpg,.jpeg"-->
        <!--            >-->
        <!--              <img :src="form.avatar ? baseURL + form.avatar : avator" class="avatar" />-->
        <!--              <div class="wrap">-->
        <!--                <div>上传头像</div>-->
        <!--              </div>-->
        <!--            </el-upload>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item label="昵称" required>-->
        <!--            <el-input v-model="form.username" max-length="20" placeholder="请输入昵称，最多可输入20字"></el-input>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item label="姓名" required>-->
        <!--            <el-input v-model="form.realname" placeholder="请输入姓名"></el-input>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item label="性别" required>-->
        <!--            <el-select v-model="form.sex" placeholder="请选择性别">-->
        <!--              <el-option-->
        <!--                  v-for="item in options"-->
        <!--                  :key="item.value"-->
        <!--                  :label="item.label"-->
        <!--                  :value="item.value"-->
        <!--              >-->
        <!--              </el-option>-->
        <!--            </el-select>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item label="出生年月" required>-->
        <!--            <el-date-picker-->
        <!--                v-model="form.birthday"-->
        <!--                type="date"-->
        <!--                placeholder="选择日期"-->
        <!--            >-->
        <!--            </el-date-picker>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item label="所在行业" required>-->
        <!--            <el-input-->
        <!--                v-model="form.industry"-->
        <!--                placeholder="请输入所在行业"-->
        <!--            ></el-input>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item label="所属公司" required>-->
        <!--            <el-input-->
        <!--                v-model="form.company"-->
        <!--                placeholder="请输入所属公司"-->
        <!--            ></el-input>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item label="职务" required>-->
        <!--            <el-input v-model="form.duty" placeholder="请输入职务"></el-input>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item label="阅读兴趣" required>-->
        <!--            <el-input-->
        <!--                v-model="form.interest"-->
        <!--                placeholder="请输入阅读兴趣"-->
        <!--            ></el-input>-->
        <!--          </el-form-item>-->
        <!--          <div class="submit" @click="submit()">确认</div>-->
        <!--        </el-form>-->
        <!--      </div>-->
      </div>
      <div>
        <div>
          <recruitUpload></recruitUpload>
        </div>
      <div class="recruitStyle1">
        <div class="name"> 在线简历完整度</div>
        <div class="recruit">
          <div style="display: flex">
            <div class="border">
              <div :style="widthStyle"  class="schedule"></div>
            </div>
            <div class="title">{{schedule}}%</div>
          </div>

          <div class="title2">
            持续优化简历，获更强职业竞争力!
          </div>
         </div>

      </div></div>
    </div>
    <div class="noData" v-else>
      <img loading="lazy" src="@/assets/none.png" alt="" />
      暂无数据
    </div>
  </div>
</template>
<script>
import {
  userUpdate,
} from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
import recruitUpload from "@/views/candidate/recruitUpload.vue";
import {proCityList} from "@/api/city";
export default {
  name: "Info",
  components: { recruitUpload },
  data() {
    return {

      editInfo: 0,
      editAdvantage:0,
      jobAdd: 1,
      languageSelect: "",
      baseURL: this.$utils.baseURL,
      labelPosition: "left",
      avator: require("@/assets/avator.png"),
      options: [],
      optionsCity: [],
      optionsType: [],
      optionsIndustry: [],
      optionsSalary: [],
      optionsEdu: [],
      optionPosition:[],
      schedule:0,
      file: {},
      form: {},
      provinces: proCityList,
      userId: localStorage.getItem("userId"),

      // userId:1,
      token: localStorage.getItem("access_token" ),
      // token: "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImI0ODQ4ZGRlLTg4ODEtNGFhNy1iNzRmLTgwZTM5NmMxZmU2YyJ9.LMY_3ane52i9K_vJ3NaBeF5rkMx-jCSLPZI2rFClTkqOVAT3amV1O-FJkZD4BHJyo1wOv-J6K-eDdKzdCyF6UQ",
    };
  },
  computed: {
    widthStyle() {
      return { width: `${this.schedule}%` }; // 将宽度数据转换为百分比形式的CSS样式
    },
  },
  created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    if (this.$store.state.userId) {
      this.getUser();
      this.jobStatus();
      this.jobCity();
      this.jobType();
      this.jobIndustry();
      this.jobSalary();
      this.jobEdu();
      this.jobPosition();
      this.getSchedule();
      this.jobCitythis()
    }
    this.getUser();
    this.jobStatus();
    this.jobCity();
    this.jobType();
    this.jobIndustry();
    this.jobSalary();
    this.jobEdu();
    this.jobPosition();
    this.getSchedule();
    let url = window.location.host;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气人才",
        text: "全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    jobCitythis(){
      this.provinces=this.transformCityData(this.provinces);
    },
    transformCityData(data) {
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.name,
          label: item.name,
          children: item.children ? this.transformCityDataChild(item.children) : []
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },
    transformCityDataChild(data) {
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.name,
          label: item.name,
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },

    // 修改性别
    sexChange(item) {
      this.form.sex = item;
    },
    // 修改海外工作经历
    overChange(item) {
      item.workExperienceOverseas= '0';
      console.log( this.form)
    },
    overChange1(item) {
      item.workExperienceOverseas= '1';
      console.log( this.form)
    },
    // 修改是否统招
    eduChange(item) {
      item.eduRally= '0';
      console.log( this.form)
    },
    eduChange1(item) {
      item.eduRally= '1';
      console.log( this.form)
    },
    // 获取期望职位状态
    jobStatus() {
      this.$axios
        .get("/system/status/list")
        .then((res) => {
          this.options = res.data.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取城市
    jobCity() {
      this.$axios
        .get("/system/city/list")
        .then((res) => {
          this.optionsCity = res.data.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取职位状态
    jobType() {
      this.$axios
        .get("/system/type/list")
        .then((res) => {
          this.optionsType = res.data.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取行业
    jobIndustry() {
      this.$axios
        .get("/system/industry/list")
        .then((res) => {
          this.optionsIndustry = res.data.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取薪资
    jobSalary() {
      this.$axios
        .get("/system/salary/list")
        .then((res) => {
          this.optionsSalary = res.data.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取学历
    jobEdu() {
      this.$axios
        .get("/system/education/list")
        .then((res) => {
          this.optionsEdu = res.data.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取职位
 jobPosition(){
   this.$axios
       .get("/system/position/name_list")
       .then((res) => {
         this.optionPosition= res.data.rows;
       })
       .catch((err) => {
         console.log(err);
       });
 },
    // 获取进度条
    getSchedule(){
      this.$axios
          .get("/system/job_user/schedule/"+this.userId)
          .then((res) => {
            this.schedule= res.data.data;
            console.log("jindu",typeof(this.schedule))
          })
          .catch((err) => {
            console.log(err);
          });
    },

    // 基础信息
    edit() {
      this.editInfo = 1;
    },

    // 期望求职
    addJob() {
      this.form.expectations.push({
        city: "",
        industry: "",
        position: "",
        salary: "",
        type: "",
        userId: parseInt(this.userId),
      });
      // var data = [
      //   {
      //     city: "",
      //     industry: "",
      //     position: "",
      //     salary: "",
      //     type: "",
      //     id: Date.now(),
      //     userId: parseInt(this.userId),
      //   },
      // ];
      // this.$axios
      //   .post("/system/expectations", data, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: this.token,
      //     },
      //   })
      //   .then((res) => {
      //     console.log(res.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    editJob(item) {
      item.jobedit = 1;
      item.userId=this.userId
      console.log(item)
      this.$forceUpdate();
    },
    deleteJob(item) {
      this.$axios
        .delete("/system/expectations/" + item.id)
        .then((res) => {
          this.$message({
            message: res.data.msg, //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              // this.$router.go();
                this.getUser()
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //工作经历
    addwork() {
      this.form.workExperience.push({
        workCompanyName: "",
        workPositionName: "",
        workIndustry: "",
        workStartTime: "",
        workEndTime: "",
        workExperienceContent: "",
        workExperienceOverseas:"",
        userId: parseInt(this.userId),
      });
      // var data = [
      //   {
      //     city: "",
      //     industry: "",
      //     position: "",
      //     salary: "",
      //     type: "",
      //     id: Date.now(),
      //     userId: parseInt(this.userId),
      //   },
      // ];
      // this.$axios
      //   .post("system/work_experience", data, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: this.token,
      //     },
      //   })
      //   .then((res) => {
      //     console.log(res.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    deleteWork(item) {
      this.$axios
        .delete("/system/work_experience/" + item.id)
        .then((res) => {
          this.$message({
            message: res.data.msg, //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
                this.getUser()
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 教育经历
    addedu() {
      this.form.eduExperience.push({
        eduEducation: "",
        eduEndTime: "",
        eduExperienceLocation: "",
        eduHonor: "",
        eduPaper: "",
        eduSpeciality: "",
        eduStartTime: "",
        eduRally:'',
        userId: parseInt(this.userId),
      });
      // var data = [
      //   {
      //     eduEducation: "",
      //     eduEndTime: "",
      //     eduExperienceLocation: "",
      //     eduHonor: "",
      //     eduPaper: "",
      //     eduSpeciality: "",
      //     eduStartTime: "",
      //     userId: parseInt(this.userId),
      //     id: Date.now(),
      //   },
      // ];
      // this.$axios
      //   .post("/system/edu_experience", data, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: this.token,
      //     },
      //   })
      //   .then((res) => {
      //     console.log(res.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    editEdu(item) {
      console.log(item);
      item.jobedit = 1;
      this.$forceUpdate();
    },
    deleteEdu(item) {
      this.$axios
        .delete("/system/edu_experience/" + item.id)
        .then((res) => {
          this.$message({
            message: res.data.msg, //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
                this.getUser()
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 项目经历
    addPro() {
      this.form.projectExperience.push({
        projectContent: "",
        projectEndTime: "",
        projectName: "",
        projectPerformance: "",
        projectRole: "",
        projectStartTime: "",
        userId: parseInt(this.userId),
      });
      // var data = [
      //   {
      //     projectContent: "",
      //     projectEndTime: "",
      //     projectName: "",
      //     projectPerformance: "",
      //     projectRole: "",
      //     projectStartTime: "",
      //     userId: parseInt(this.userId),
      //     id: Date.now(),
      //   },
      // ];
      // this.$axios
      //   .post("/system/project_experience", data, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: this.token,
      //     },
      //   })
      //   .then((res) => {
      //     console.log(res.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    editPro(item) {
      console.log(item);
      item.jobedit = 1;
      item.userId=this.userId
      this.$forceUpdate();
    },
    deletePro(item) {
      this.$axios
        .delete("/system/project_experience/" + item.id)
        .then((res) => {
          this.$message({
            message: res.data.msg, //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
                this.getUser()

            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
      this.getUser()
    },
    // 获取简历
    getUser() {
      this.$axios
        .get("/system/job_user/" + this.userId)
        .then((res) => {
          this.form = res.data.data;
          this.getSchedule()
          console.log("--------ren")
          console.log(this.form.birthday);
          var birthDate = new Date(this.form.birthday);
          // 获取当前日期
          var today = new Date();
          // 获取当前年份
          var currentYear = today.getFullYear();

          // 计算年龄
          var age = currentYear - birthDate.getFullYear();

          // 如果生日还没有到，年龄需要减去1
          if (today.getMonth() < birthDate.getMonth() ||
              (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
            age--;
          }

          this.form.old=age

          // 将日期字符串转换为Date对象
          var start = new Date(this.form.workHour);
          var end = new Date();

          // 计算年份差
          var yearsDiff = end.getFullYear() - start.getFullYear();

          // 如果开始年份的日期大于结束年份的日期，则年份差减1
          if (start.getMonth() > end.getMonth() ||
              (start.getMonth() === end.getMonth() && start.getDate() > end.getDate())) {
            yearsDiff--;
          }

          // 计算月份差
          var monthsDiff = end.getMonth() - start.getMonth();

          // 如果月份差为负数，说明开始年份的日期在结束年份的日期之后，需要调整
          if (monthsDiff < 0) {
            monthsDiff += 12; // 加上12个月
            yearsDiff--;
          }
          console.log("gongzuo",yearsDiff)
          if(yearsDiff>0&&monthsDiff>0){
            this.form.workHourCom="工作"+yearsDiff+"年零"+monthsDiff+"个月"
          }else if(yearsDiff==0){
            this.form.workHourCom="工作"+monthsDiff+"个月"
          }
          else if(monthsDiff==0){
            this.form.workHourCom="工作"+yearsDiff+"年"
          }


          this.form.expectations.forEach(function (element) {
            element.jobedit = 0;
          });
          this.form.workExperience.forEach(function (element) {
            element.jobedit = 0;
          });
          this.form.eduExperience.forEach(function (element) {
            element.jobedit = 0;
          });
          this.form.projectExperience.forEach(function (element) {
            element.jobedit = 0;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 期望求职
    cancleSubmit() {
      this.getUser();
      this.editInfo = 0
    },
    confirmSubmit() {
      if (!this.form.userName) {
        this.$message.error("请输入您的昵称");
        return false;
      }

      if (this.form.sex === null) {
        this.$message.error("请选择您的性别");
        return false;
      }
      if (!this.form.birthday) {
        this.$message.error("请选择您的出生年月");
        return false;
      }
      // if (!this.form.phonenumber) {
      //   this.$message.error("请输入您的手机号码");
      //   return false;
      // }
      // if (!this.form.email) {
      //   this.$message.error("请输入您的邮箱");
      //   return false;
      // }

      // this.form.jobStatus=this.form.jobStatus
      // this.form.access_token="pwfv3OXON4u-9RZbJe2s6johNmtePwv-p7Ij6Fl3sdpx9lIrQUqZCqQm5-vSChDf7kCtlT-43VTf4z3lCJcZ0dMFOJqyR1YwR96Aq5UKAn3TNDrW50-HGjIVeJkDly5n"

      if(this.form.currentCity[0]==="热门城市"){
        this.form.currentCity=this.form.currentCity[1]
      }else{
        const city=this.form.currentCity[0]+""+this.form.currentCity[1]
        this.form.currentCity=city
      }

      this.form.access_token = localStorage.getItem("Zyaccess_token"); // 竹云token
      userUpdate(this.form).then((res) => {
        localStorage.setItem("typeInfo", 1);
        this.$store.state.typeInfo = 1;
        this.$message({
          message: res.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload()
              this.getUser()
          },
        });
      });
    },
    // 期望
    confirmSubmitJob(jobs) {
      var formJob = {
        userId: jobs.userId,
        type: jobs.type,
        position: jobs.position,
        salary: jobs.salary,
        industry: jobs.industry,
        city: jobs.city,
      };

      if (!formJob.position) {
        this.$message.error("请输入您期望的职位");
        return false;
      }
      if (!formJob.salary) {
        this.$message.error("请输入您期望的薪资范围");
        return false;
      }
      if (!formJob.industry) {
        this.$message.error("请输入您期望的工作行业");
        return false;
      }
      if (!formJob.city) {
        this.$message.error("请输入您期望的工作地点");
        return false;
      }
      if(jobs.city[0]==="热门城市"){
        jobs.city=jobs.city[1]
      }else{
        const city=jobs.city[0]+"-"+jobs.city[1]
        jobs.city=city
      }
      // console.log("job",jobs.id)
      // console.log("job",jobs.id===undefined)
      if(jobs.id===undefined){
        this.$axios
            .post("/system/expectations", [{
              userId: jobs.userId,
              type: jobs.type,
              position: jobs.position,
              salary: jobs.salary,
              industry: jobs.industry,
              city: jobs.city,
            }], {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
              },
            })
            .then((res) => {
              console.log(res);
              this.getUser()
            })
            .catch((err) => {
              console.log(err);
            });
      }
      else{
        this.$axios
            .put("/system/expectations", {
              userId: jobs.userId,
              type: jobs.type,
              position: jobs.position,
              salary: jobs.salary,
              industry: jobs.industry,
              city: jobs.city,
              id:jobs.id
            }, {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
              },
            })
            .then((res) => {
              console.log(res);
              this.getUser()
            })
            .catch((err) => {
              console.log(err);
            });
      }

      // jobUpdate(formJob).then((res) => {
      //   this.$message({
      //     message: res.msg, //提示的信息
      //     type: "success", //类型是成功
      //     duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
      //     onClose: () => {
      //       //跳转页面或执行方法
      //         this.getUser()
      //     },
      //   });
      // });
    },
    cancleSubmitJob() {
      this.getUser();
    },
    // 工作
    confirmSubmitWork(jobs) {
      var formJob = {
        userId: jobs.userId,
        workCompanyName: jobs.workCompanyName,
        workEndTime: jobs.workEndTime,
        workExperienceContent: jobs.workExperienceContent,
        workIndustry: jobs.workIndustry,
        workPositionName: jobs.workPositionName,
        workStartTime: jobs.workStartTime,
        workExperienceOverseas: jobs.workExperienceOverseas,
      };
      if (!formJob.workCompanyName) {
        this.$message.error("请输入您任职的公司名称");
        return false;
      }
      if (!formJob.workStartTime) {
        this.$message.error("请输入您的入职时间");
        return false;
      }
      if (!formJob.workEndTime) {
        this.$message.error("请输入您的离职时间");
        return false;
      }
      if (!formJob.workIndustry) {
        this.$message.error("请输入您期望的工作行业");
        return false;
      }
      if (!formJob.workExperienceContent) {
        this.$message.error("请输入您期望的工作内容");
        return false;
      }
      console.log(jobs.id)
      if(jobs.id===undefined){
        this.$axios
            .post("system/work_experience", [{
              userId: jobs.userId,
              workCompanyName: jobs.workCompanyName,
              workEndTime: jobs.workEndTime,
              workExperienceContent: jobs.workExperienceContent,
              workIndustry: jobs.workIndustry,
              workPositionName: jobs.workPositionName,
              workStartTime: jobs.workStartTime,
              workExperienceOverseas: jobs.workExperienceOverseas,
            }], {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
              },
            })
            .then((res) => {
              console.log(res.data);
              this.getUser()
            })
            .catch((err) => {
              console.log(err);
            });
      }
      else{
        this.$axios
            .put("system/work_experience", {
              id:jobs.id,
              userId: jobs.userId,
              workCompanyName: jobs.workCompanyName,
              workEndTime: jobs.workEndTime,
              workExperienceContent: jobs.workExperienceContent,
              workIndustry: jobs.workIndustry,
              workPositionName: jobs.workPositionName,
              workStartTime: jobs.workStartTime,
              workExperienceOverseas: jobs.workExperienceOverseas,
            }, {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
              },
            })
            .then((res) => {
              console.log(res.data);
              this.getUser()
            })
            .catch((err) => {
              console.log(err);
            });
      }



      // workUpdate(formJob).then((res) => {
      //   this.$message({
      //     message: res.msg, //提示的信息
      //     type: "success", //类型是成功
      //     duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
      //     onClose: () => {
      //       //跳转页面或执行方法
      //         this.getUser()
      //     },
      //   });
      // });
    },
    cancleSubmitWork() {
      this.getUser();
    },
    // 教育
    confirmSubmitEdu(jobs) {
      var formJob = {
        eduEducation: jobs.eduEducation,
        eduEndTime: jobs.eduEndTime,
        eduExperienceLocation: jobs.eduExperienceLocation,
        eduHonor: jobs.eduHonor,
        eduPaper: jobs.eduPaper,
        eduSpeciality: jobs.eduSpeciality,
        eduStartTime: jobs.eduStartTime,
        userId: jobs.userId,
      };
      if (!formJob.eduExperienceLocation) {
        this.$message.error("请输入您的学校名称");
        return false;
      }
      if (!formJob.eduStartTime) {
        this.$message.error("请输入您的入学时间");
        return false;
      }
      if (!formJob.eduEndTime) {
        this.$message.error("请输入您的毕业时间");
        return false;
      }

      if (!formJob.eduHonor) {
        this.$message.error("请输入您在校期间所获荣誉");
        return false;
      }

      if(jobs.id===undefined){
        this.$axios
            .post("/system/edu_experience", [{
              eduEducation: jobs.eduEducation,
              eduEndTime: jobs.eduEndTime,
              eduExperienceLocation: jobs.eduExperienceLocation,
              eduHonor: jobs.eduHonor,
              eduPaper: jobs.eduPaper,
              eduSpeciality: jobs.eduSpeciality,
              eduStartTime: jobs.eduStartTime,
              eduRally:jobs.eduRally,
              userId: jobs.userId,
            }], {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
              },
            })
            .then((res) => {
              console.log(res.data);
              this.getUser()
            })
            .catch((err) => {
              console.log(err);
            });
      }
      else{
        this.$axios
            .put("/system/edu_experience", {
              eduEducation: jobs.eduEducation,
              eduEndTime: jobs.eduEndTime,
              eduExperienceLocation: jobs.eduExperienceLocation,
              eduHonor: jobs.eduHonor,
              eduPaper: jobs.eduPaper,
              eduSpeciality: jobs.eduSpeciality,
              eduStartTime: jobs.eduStartTime,
              userId: jobs.userId,
              eduRally:jobs.eduRally,
              id:jobs.id,
            }, {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
              },
            })
            .then((res) => {
              console.log(res.data);
              this.getUser()
            })
            .catch((err) => {
              console.log(err);
            });
      }


      // eduUpdate(formJob).then((res) => {
      //   this.$message({
      //     message: res.msg, //提示的信息
      //     type: "success", //类型是成功
      //     duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
      //     onClose: () => {
      //       //跳转页面或执行方法
      //         this.getUser()
      //     },
      //   });
      // });
    },
    cancleSubmitEdu() {
      this.getUser();
    },
    // 项目
    confirmSubmitPro(jobs) {
      console.log(jobs.userId)
      var formJob = {
        projectContent: jobs.projectContent,
        projectEndTime: jobs.projectEndTime,
        projectName: jobs.projectName,
        projectPerformance: jobs.projectPerformance,
        projectRole: jobs.projectRole,
        projectStartTime: jobs.projectStartTime,
        userId: jobs.userId,
        companyName:jobs.companyName
      };
      if (!formJob.companyName) {
        this.$message.error("请输入项目所属的公司名称");
        return false;
      }
      if (!formJob.projectName) {
        this.$message.error("请输入项目名称");
        return false;
      }
      if (!formJob.projectStartTime) {
        this.$message.error("请输入项目开始时间");
        return false;
      }
      if (!formJob.projectEndTime) {
        this.$message.error("请输入项目结束时间");
        return false;
      }

      if (!formJob.projectContent) {
        this.$message.error("请简要介绍您的项目");
        return false;
      }
      if (!formJob.projectPerformance) {
        this.$message.error("请简要介绍您的项目业绩");
        return false;
      }
      if(jobs.id===undefined){
        this.$axios
            .post("/system/project_experience", [{
              projectContent: jobs.projectContent,
              projectEndTime: jobs.projectEndTime,
              projectName: jobs.projectName,
              projectPerformance: jobs.projectPerformance,
              projectRole: jobs.projectRole,
              projectStartTime: jobs.projectStartTime,
              userId: jobs.userId,
              companyName:jobs.companyName
            }], {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
              },
            })
            .then((res) => {
              console.log(res.data);
              this.getUser()
            })
            .catch((err) => {
              console.log(err);
            });
      }
      else{
        this.$axios
            .put("/system/project_experience", {
              id:jobs.id,
              projectContent: jobs.projectContent,
              projectEndTime: jobs.projectEndTime,
              projectName: jobs.projectName,
              projectPerformance: jobs.projectPerformance,
              projectRole: jobs.projectRole,
              projectStartTime: jobs.projectStartTime,
              userId: jobs.userId,
              companyName:jobs.companyName
            }, {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
              },
            })
            .then((res) => {
              console.log(res.data);
              this.getUser()
            })
            .catch((err) => {
              console.log(err);
            });
      }


    },
    cancleSubmitPro() {
      this.getUser();
    },
    handleAvatarSuccess(res) {
      this.form.avatar = res.fileName;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 文件上传失败（http）
    handleAvatarError() {
      this.$message.error("上传失败! （http失败）");
    },
  },
};
</script>
<style scoped lang="scss">
.box {
  width: 80%;
  background: #fff;
  .box1 {
    ::v-deep .el-input__prefix {
      left: 90%;
      transition: all 0.3s;
    }
    ::v-deep .el-input.is-disabled .el-input__inner {
      background-color: white !important;
      border-color: #e4e7ed;
      color: #c0c4cc;
      cursor: not-allowed;
    }
    height: fit-content;
    padding: 24px 30px 26px 30px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    .cancleStyle {
      margin-top: 46px;
      width: 128px;
      height: 44px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #bf0022;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #bf0022;
      line-height: 20px;
      -webkit-background-clip: text;
    }
    .confirmStyle {
      margin-top: 46px;
      margin-left: 20px;
      cursor: pointer;
      border: none;
      width: 128px;
      height: 44px;
      background-color: #bf0022;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
      position: relative;
    }

    ::v-deep .el-input--prefix .el-input__inner {
      padding-left: 15px;
    }
    ::v-deep .el-select {
      width: 100%;
    }
    ::v-deep .el-input__suffix {
      height: 100%;
      left: 88%;
      transition: all 0.3s;
      pointer-events: none;
    }

    .information {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 48px;
      row-gap: 20px;
      padding-top: 32px;
      .title {
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 14px;
        -webkit-background-clip: text;
      }
      .titleInput {
        margin-top: 12px;
        .sexStyle1 {
          width: 45%;
          height: 44px;
          background: #f8e9ecff;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #bf0022;
          font-size: 16px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #bf0022;
          line-height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .sexStyle2 {
          width: 45%;
          height: 44px;
          background: #fdfdfdff;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: none;
          font-size: 16px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: gray;
          cursor: pointer;
          line-height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .editStyle {
      position: absolute;
      top: 0;
      display: flex;
      right: 0;
      height: 12px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      cursor: pointer;
      .editLogo {
        width: 13px;
        height: 12px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
      .editTitle {
        width: 28px;
        height: 16px;
        font-size: 14px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #bf0022;
        line-height: 16px;
        margin-left: 7px;
      }
    }

    .Photo {
      width: 80px;
      height: 80px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .userName {
      height: 24px;
      font-size: 20px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: bold;
      color: #333333;
      line-height: 24px;
    }
    .userName1 {
      margin-top: 9px;
      width: 80px;
      height: 24px;
      font-size: 20px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #bf0022;
      line-height: 24px;
      -webkit-background-clip: text;
    }
    .userName2 {
      margin-top: 14px;
      width: 720px;
      color: gray;
      height: 24px;
      opacity: 1;
    }
    .userSex {
      height: 16px;
      font-size: 16px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      margin-left: 6px;
      margin-right: 32px;
    }
    .iconStyle {
      width: 14px;
      height: 15px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
  .box2 {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-top: 16px;
    height: fit-content;
    padding: 23px 14px 32px 14px;
    .cancleStyle {
      margin-top: 20px;
      width: 128px;
      height: 44px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #bf0022;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #bf0022;
      line-height: 20px;
      -webkit-background-clip: text;
    }
    .confirmStyle {
      margin-top: 20px;
      margin-left: 20px;
      cursor: pointer;
      border: none;
      width: 128px;
      height: 44px;
      background-color: #bf0022;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .information {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 48px;
      row-gap: 20px;

    }
    .title {
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 14px;
      -webkit-background-clip: text;
    }
    .titleInput {
      margin-top: 12px;
      .sexStyle1 {
        width: 205px;
        height: 44px;
        background: #f8e9ecff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #bf0022;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #bf0022;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .sexStyle2 {
        width: 205px;
        height: 44px;
        background: #fdfdfdff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: none;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: gray;
        cursor: pointer;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .addStyle {
      cursor: pointer;
      position: absolute;
      display: flex;
      right: 30px;
    }
    .addStyle2 {
      position: absolute;
      display: flex;
      right: 16px;
    }
    .addStyle1 {
      position: absolute;
      display: flex;
      right: 96px;
    }
    .workStyle2 {
      position: absolute;
      display: flex;
      right: 16px;
      top: 19px;
    }
    .workStyle1 {
      position: absolute;
      display: flex;
      right: 96px;
      top: 19px;
    }
    .addLogo {
      width: 16px;
      height: 16px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .addTitle {
      width: 28px;
      height: 16px;
      font-size: 14px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #bf0022;
      line-height: 16px;
      margin-left: 7px;
    }
    .jobName {
      margin-left: 16px;
      width: fit-content;
      height: 24px;
      font-size: 20px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: bold;
      color: #222222;
      line-height: 24px;
    }
    .jobName1 {
      width: 80px;
      height: 24px;
      font-size: 20px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: bold;
      color: #222222;
      line-height: 24px;
    }
    .jobClass {
      cursor: pointer;
      margin-top: 21px;
      width: 100%;
      list-style: none;
      .jobStyle {
        position: relative;
        display: flex;
        height: fit-content;
        padding: 8px 16px;
      }
      .jobStyle1 {
        position: relative;
        height: fit-content;
        width: 100%;
        margin-top: 12px;
      }
      .actions {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }
      .jobStyle:hover {
        background: #f8f8f8;
      }
      .jobStyle:hover .actions {
        opacity: 1;
        visibility: visible;
      }
      .workName {
        height: 24px;
        font-size: 14px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #222222;
        line-height: 24px;
      }
      .span {
        width: 1px;
        height: 12px;
        background: #cccccc;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 6px 10px;
      }
    }
    .workClass {
      cursor: pointer;
      width: 100%;
      list-style: none;
      .workStyle {
        margin-top: 21px;
        position: relative;
        width: 100%;
        height: auto;
        padding: 19px 0 19px 16px;
        background: #f8f8f8;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        .workName {
          height: 24px;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
        .span {
          width: 1px;
          height: 12px;
          background: #cccccc;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          margin: 6px 10px;
        }
        .workCompanyName {
          margin-top: 4px;
          height: 16px;
          font-size: 16px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #333333;
          line-height: 16px;
        }
        .workTime {
          height: 24px;
          font-size: 12px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #999999;
          line-height: 24px;
          margin-left: 16px;
        }
        .photo{
          width:70px;
          padding:0 5px;
          background-color: #e9eaea;
          margin:0 15px 0 0;
          border-radius: 5px;
          height: fit-content;
          border: white 1px solid;


        }
        .industry {
          width: fit-content;
          margin-top: 4px;
          height: 16px;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #333333;
          line-height: 16px;
        }
        .indus {
          height: fit-content;
          word-wrap: break-word; /* 允许在单词内换行 */
          width: 87%;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
        .indusLunwen {
          height: fit-content;
          word-wrap: break-word; /* 允许在单词内换行 */
          width: 85%;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
        .industry2 {
          width: 70px;
          height: 28px;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #333333;
          line-height: 28px;
        }
        .indusContent {
          word-wrap: break-word; /* 允许在单词内换行 */
          width: 89%;
          height: auto;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #666666;
          line-height: 28px;
        }
      }
      .workActions {
        opacity: 0;

        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }
      .workStyle:hover {
        background: #f8f8f8;
      }
      .workStyle:hover .workActions {
        opacity: 1;
        margin-right: -55px;
        visibility: visible;
      }
    }
  }

  ::v-deep .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
    position: relative;
  }

  ::v-deep .el-select {
    width: 100%;
  }
  ::v-deep .el-input__suffix {
    height: 100%;
    left: 88%;
    transition: all 0.3s;
    pointer-events: none;
  }
  .ViewTitle {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #ebebeb;
    font-size: 20px;
    color: #333333;
    font-weight: 400;
    padding: 0 30px;
  }
  .contain {
    margin: 0 30px;
    padding-bottom: 64px;
    padding-top: 30px;
    .submit {
      width: 320px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      border-radius: 28px;
      background: var(--main-color);
      margin-left: 100px;
      cursor: pointer;
      margin-top: 56px;
    }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar,
  .boxCardUploadImg,
  .box .avatar-uploader-icon,
  .el-upload {
    width: 80px !important;
    height: 80px !important;
    display: block !important;
    border-radius: 36px;
  }
  .el-upload {
    position: relative;
    overflow: hidden;
  }

  .boxCardUploadImg .el-upload div.wrap {
    // display: none;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    div {
      width: 72px;
      height: 24px;
      line-height: 20px;
      color: #ffffff;
      font-size: 10px;
      cursor: pointer;
      background: rgba($color: #000000, $alpha: 0.4);
      overflow: hidden !important;
    }
  }
}
.recruitStyle1{
  margin-top: 25px;
  width: 260px;
  position: relative;
  height: 269px;

  border-radius: 5px;
  //background-image: url("../../assets/recruitBg.png");
  margin-left: 16px;
  //border: solid 1px #b8e5fa;
  border-bottom: none;
  //background-size:contain;
  //background-position:center;
  //background-repeat:no-repeat;

  text-align: center;
  .recruit{

    position: absolute;
    top:58px;
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding:20px 15px ;
    .border{
      width: 80%;
      height: 10px;
      margin-top: 2px;
      background-color: rgba(241, 240, 240, 0.8);
      border-radius: 53px;

    }
    .schedule{
      height: 100%;
      border-radius: 53px;
      background:linear-gradient(to right, rgb(253, 212, 81) 0%, rgb(253, 186, 90) 50%, rgb(253, 114, 13) 100%);
    }
    .title{
      color: rgb(253, 114, 13);
      font-size: 14px;
      width: 20%;
      text-align: center;
    }
    .title2{
      margin-top: 15px;
      font-size: 14px;
      text-align: left;
    }
  }
  .name{
    border-top-left-radius: 5px;
    border-top-right-radius:5px ;
    background:linear-gradient(to bottom, rgb(182, 231, 252) 50%, rgb(220, 243, 253) 100%);
    width: 100%;
    height: fit-content;
    font-size: 18px;
    font-weight: 500;
    color: black;
    line-height: 32px;
    text-align: left;
    padding:14px 20px;
  }
  .name1{
    z-index: 99999;
    margin: 30px auto 20px auto;
    width: 64px;
    height: 18px;
    font-size: 16px;
    font-weight: 400;
    color:black;
    line-height: 18px;
  }
  .name2{
    z-index: 99999;
    margin: 10px auto;
    width: 212px;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
  }
  .name3{
    z-index: 99999;
    margin: 20px auto;
    width: 220px;
    height: 40px;
    background: #FAEBEE;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
    color: #BF0022;
    align-items: center;
    justify-content: center;
    display: flex;
    line-height: 32px;
    cursor: pointer;
  }
  .name3:hover{

    background:#BF0022;

    color: white;
  }

  .name4{
    margin: 6px 10px;
    display: flex;
    .pdfImg{
      width: 23px;
      position: absolute;
      height: 25px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .pdfTitle{
      text-align: left;
      cursor: pointer;
      height: 18px;
      margin-top: 6px;
      margin-left: 25px;
      font-size: 14px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #222222;
      line-height: 18px;
      -webkit-background-clip: text;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

    }
    .pdfTitle:hover{
      color: #E85353FF;
    }
    .pdfTitle2:hover{
      color: #E85353FF;
    }
    .pdfTitle2{
      cursor: pointer;
      margin-top: 6px;
      font-size: 14px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #222222;
      line-height: 18px;
      -webkit-background-clip: text;
    }
    .pdfDelete{
      margin: 4px 4px 4px auto;
      color: #222222;
      display: none;
    }

  }
  .name4:hover .pdfDelete{
    display: block;
    color: rgba(34, 34, 34, 0.6);
    cursor: pointer;
  }
}
::v-deep {
  .contain {
    .el-select {
      width: 100%;
    }
    .el-input__suffix {
      height: 100%;
      left: 88%;
      transition: all 0.3s;
      pointer-events: none;
    }
    .el-input__inner {
      line-height: 50px;
      height: 50px;
      font-size: 16px;
      background: inherit;
      color: #333;
    }
    .el-input__inner::placeholder {
      color: #bbbbbb;
    }

    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
      border-color: #dcdfe6;
    }
    .el-form-item {
      width: fit-content;
      margin-bottom: 36px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    .el-form-item__content {
      width: 400px;
      background: #f5f5f7;
      border-radius: 4px;
    }
    .avatorBox .el-form-item__content {
      width: auto;
      background: none;
      border-radius: 100%;
    }
    .el-input__prefix {
      left: auto;
      right: 5px;
      .el-input__icon.el-icon-date {
        transform: rotate(180deg);
        font-size: 20px;
        &:before {
          content: "\e6e1";
        }
      }
    }
    .el-input--prefix .el-input__inner {
      padding-left: 15px;
    }
    .el-select__caret {
      font-size: 20px;
    }
  }
}
input {
  border: none;
  outline: none;
}
.box .avatar-uploader-icon {
  width: 72px !important;
  height: 72px !important;
  display: block !important;
  border-radius: 36px;
  line-height: 72px;
}
</style>
