<template>
<div>
  <div class="recruitStyle">
    <div class="name">简历管理</div>
    <div class="recruit" v-if="recurite.length!==0">

      <div style="list-style: none;margin-top: 10px">
        <li  v-for="(recurite,index) in recurite" :key="index">
          <div class="name4" >
            <div class="pdfImg" @click="detailPdf(recurite)">    <img loading="lazy" src="../../assets/pdf.png"/></div>
            <div class="pdfTitle" @click="detailPdf(recurite)">
              {{recurite.fileName}}
            </div>
            <div class="pdfTitle2" @click="detailPdf(recurite)">
              .pdf
            </div>

            <div class="pdfDelete" @click="Pdfdelete(recurite)">
              <i class="el-icon-delete-solid" style="color: #E85353FF"></i>
            </div>
          </div>
        </li>


      </div>
      <div class="name3" @click="showUpload">
        上传附件简历
      </div>
    </div>
    <div class="recruit" v-else>
      <div class="name1">导入简历</div>
      <div class="name2">
        请上传PDF格式的中文简历，大小不超过20M
      </div>
      <div class="name3" @click="showUpload">
        上传附件简历
      </div>
    </div>
    <uploadTip
        :dialogVisible="upload"
        @hideDialog="hideupload"
    ></uploadTip>

    <pdfScan
        :dialogVisible="pdf"
        :file-path="filePath"
        @hideDialog="hidethis"
    ></pdfScan>
  </div>

</div>



</template>


<script>
import uploadTip from "@/views/userlog/uploadTip.vue";
import pdfScan from "@/views/userlog/pdfScan.vue";
export default {

  name: "",

  props: [],

  components: { uploadTip,pdfScan},

  data() {

    return {

      filePath:"",
      resume:[],
      recurite:[],
      upload:false,
      pdf:false,
      trueResume:"",
      userId: localStorage.getItem("userId"),
      token:localStorage.getItem("access_token")
    }

  },
  created() {
    this.getResume()


  },

  methods: {
    getResume(){
      this.$axios.get("/system/resume/"+this.userId,{
        headers: {
          Authorization: this.token,
        },
      })
          .then((res) => {
            console.log(res)
            this.recurite=res.data.rows
            this.recurite.forEach(function(element) {
              const parts = element.fileName.split('.');

              element.fileName= parts[0]; // Contains '微信图片_20230815090810'
            });


          })
          .catch((error) => {
            console.log(error);
          });
    },

    Pdfdelete(item){
      console.log(item)
      this.$axios.delete("/system/resume/"+item.id,{
        headers:{
          Authorization:this.token
        }
      }) .then((res) => {
        console.log(res)
        this.$message({
          message: res.data.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
           this.getResume()
          },
        });



      })
          .catch((error) => {
            console.log(error);
          });
    },
    detailPdf(recurite){
      this.filePath=recurite.filePath
      this.pdf=true
      console.log(this.filePath)
    },
    hideupload(){
      this.upload=false
      this.getResume()
    },
    hidethis(){
      this.pdf=false
    },
    showUpload(){
      this.upload=true
    }
  },

  mounted() {



  }

}

</script>


<style type="text/scss" lang="scss" scoped>
.recruitStyle{
  width: 260px;
  position: relative;
  min-height:100px;

  border-radius: 5px;
  //background-image: url("../../assets/recruitBg.png");
  margin-left: 16px;
  //border: solid 1px #b8e5fa;
  border-bottom: none;
  //background-size:contain;
  //background-position:center;
  //background-repeat:no-repeat;

  text-align: center;
  .recruit{

    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    padding:15px 10px 15px 10px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    //border: 1px solid #FFFFFF;
  }
  .name{
    border-top-left-radius: 5px;
    border-top-right-radius:5px ;
    background:linear-gradient(to bottom, rgb(182, 231, 252) 50%, rgb(220, 243, 253) 100%);
    width: 100%;
    height: fit-content;
    font-size: 18px;
    font-weight: 500;
    color: black;
    line-height: 32px;
    text-align: left;
    padding:14px 20px;
  }
  .name1{
    z-index: 99999;
    padding: 0 0 10px 0;
    margin: 10px auto 0 auto;
    text-align: center;
    width: 64px;
    height: 18px;
    font-size: 16px;
    font-weight: 400;
    color:black;
    line-height: 18px;
  }
  .name2{
    z-index: 99999;
    margin: 20px auto;
    width: 212px;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
  }
  .name3{
    z-index: 99999;
    margin: 20px auto 0 auto;
    width: 220px;
    height: 40px;
    background: white;
    border: #b5e5fa 1px solid;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
    color: #91dbfc;
    align-items: center;
    justify-content: center;
    display: flex;
    line-height: 32px;
    cursor: pointer;
  }
  .name3:hover{

    background: #b5e5fa;

    color: white;
  }

  .name4{
    margin: 6px 10px;
    display: flex;
    .pdfImg{
      width: 23px;
      position: absolute;
      height: 25px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .pdfTitle{
      text-align: left;
      cursor: pointer;
      height: 18px;
      margin-top: 6px;
      margin-left: 25px;
      font-size: 14px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #222222;
      line-height: 18px;
      -webkit-background-clip: text;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

    }
    .pdfTitle:hover{
      color: #E85353FF;
    }
    .pdfTitle2:hover{
      color: #E85353FF;
    }
    .pdfTitle2{
      cursor: pointer;
      margin-top: 6px;
      font-size: 14px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #222222;
      line-height: 18px;
      -webkit-background-clip: text;
    }
    .pdfDelete{
      margin: 4px 4px 4px auto;
      color: #222222;
      display: none;
    }

  }
  .name4:hover .pdfDelete{
    display: block;
    color: rgba(34, 34, 34, 0.6);
    cursor: pointer;
  }
}
.box2 {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin-top: 16px;
  height: fit-content;
  padding: 23px 14px 32px 14px;
  .cancleStyle {
    margin-top: 20px;
    width: 128px;
    height: 44px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #bf0022;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #bf0022;
    line-height: 20px;
    -webkit-background-clip: text;
  }
  .confirmStyle {
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
    border: none;
    width: 128px;
    height: 44px;
    background-color: #bf0022;
    border-radius: 4px 4px 4px 4px;
    font-size: 16px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .information {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 48px;
    row-gap: 20px;

  }
  .title {
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 14px;
    -webkit-background-clip: text;
  }
  .titleInput {
    margin-top: 12px;
    .sexStyle1 {
      width: 205px;
      height: 44px;
      background: #f8e9ecff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #bf0022;
      font-size: 16px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #bf0022;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .sexStyle2 {
      width: 205px;
      height: 44px;
      background: #fdfdfdff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: none;
      font-size: 16px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: gray;
      cursor: pointer;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .addStyle {
    cursor: pointer;
    position: absolute;
    display: flex;
    right: 30px;
  }
  .addStyle2 {
    position: absolute;
    display: flex;
    right: 16px;
  }
  .addStyle1 {
    position: absolute;
    display: flex;
    right: 96px;
  }
  .workStyle2 {
    position: absolute;
    display: flex;
    right: 16px;
    top: 19px;
  }
  .workStyle1 {
    position: absolute;
    display: flex;
    right: 96px;
    top: 19px;
  }
  .addLogo {
    width: 16px;
    height: 16px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
  .addTitle {
    width: 28px;
    height: 16px;
    font-size: 14px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #bf0022;
    line-height: 16px;
    margin-left: 7px;
  }
  .jobName {
    margin-left: 16px;
    width: fit-content;
    height: 24px;
    font-size: 20px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: bold;
    color: #222222;
    line-height: 24px;
  }
  .jobName1 {
    width: 80px;
    height: 24px;
    font-size: 20px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: bold;
    color: #222222;
    line-height: 24px;
  }
  .jobClass {
    cursor: pointer;
    margin-top: 21px;
    width: 100%;
    list-style: none;
    .jobStyle {
      position: relative;
      display: flex;
      height: fit-content;
      padding: 8px 16px;
    }
    .jobStyle1 {
      position: relative;
      height: fit-content;
      width: 100%;
      margin-top: 12px;
    }
    .actions {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0.3s ease;
    }
    .jobStyle:hover {
      background: #f8f8f8;
    }
    .jobStyle:hover .actions {
      opacity: 1;
      visibility: visible;
    }
    .workName {
      height: 24px;
      font-size: 14px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 500;
      color: #222222;
      line-height: 24px;
    }
    .span {
      width: 1px;
      height: 12px;
      background: #cccccc;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 6px 10px;
    }
  }
  .workClass {
    cursor: pointer;
    width: 100%;
    list-style: none;
    .workStyle {
      margin-top: 21px;
      position: relative;
      width: 100%;
      height: auto;
      padding: 19px 0 19px 16px;
      background: #f8f8f8;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      .workName {
        height: 24px;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }
      .span {
        width: 1px;
        height: 12px;
        background: #cccccc;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 6px 10px;
      }
      .workCompanyName {
        margin-top: 4px;
        height: 16px;
        font-size: 16px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #333333;
        line-height: 16px;
      }
      .workTime {
        height: 24px;
        font-size: 12px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        margin-left: 16px;
      }
      .photo{
        width:150px;
        padding:0 5px;
        background-color: #e9eaea;
        margin:0 10px;
        border-radius: 5px;
        height: fit-content;
        border: white 1px solid;


      }
      .industry {
        width: fit-content;
        margin-top: 4px;
        height: 16px;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #333333;
        line-height: 16px;
      }
      .indus {
        height: fit-content;
        word-wrap: break-word; /* 允许在单词内换行 */
        width: 87%;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
      .indusLunwen {
        height: fit-content;
        word-wrap: break-word; /* 允许在单词内换行 */
        width: 85%;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
      .industry2 {
        width: 70px;
        height: 28px;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #333333;
        line-height: 28px;
      }
      .indusContent {
        word-wrap: break-word; /* 允许在单词内换行 */
        width: 87%;
        height: auto;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #666666;
        line-height: 28px;
      }
    }
    .workActions {
      opacity: 0;

      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0.3s ease;
    }
    .workStyle:hover {
      background: #f8f8f8;
    }
    .workStyle:hover .workActions {
      opacity: 1;
      margin-right: -55px;
      visibility: visible;
    }
  }
}
</style>
